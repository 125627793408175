<template>
  <Sidebar class="bg-gray-100" :baseZIndex="1000" :showCloseIcon="false">
    <Menu :model="items" class="w-full">
      <template #item="{ item }">
        <router-link :to="item.to" custom v-slot="{ href, navigate, isExactActive }">
          <a
            :href="href"
            class="p-menuitem-link flex align-items-center"
            :class="{ 'active-link-exact': isExactActive }"
            @click.prevent="clickLink(navigate)">
            <span class="mr-2" :class="item.icon" />
            {{ item.label }}
            <span class="pi pi-angle-right ml-auto" />
          </a>
        </router-link>
      </template>
    </Menu>
  </Sidebar>
</template>
<script setup>
import Sidebar from "primevue/sidebar";
import Menu from "primevue/menu";

const $emits = defineEmits(["toggleSidebar"]);

const items = [
  {
    label: "Stripe Invoices",
    icon: "pi pi-book",
    to: { name: "Invoices" },
  },
  {
    label: "Shopify Invoices",
    icon: "pi pi-book",
    to: { name: "ShopifyInvoices" },
  },
  {
    label: "Payments",
    icon: "pi pi-credit-card",
    to: { name: "Payments" },
  },
  {
    label: "Schedule SMS send",
    icon: "pi pi-send",
    to: { name: "ScheduleSms" },
  },
];

const clickLink = (navigate) => {
  navigate();
  $emits("toggleSidebar");
};
</script>

<style lang="scss">
.p-menu ul {
  padding: 0.5rem 0;
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 5rem;
}
.p-sidebar-header-content {
  margin-right: auto;
}
.p-menuitem-content {
  background: transparent !important;
}
.p-menuitem-link {
  color: var(--700);
  &.active-link-exact {
    color: var(--primary-color);
  }
}
</style>
