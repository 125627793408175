<template>
  <header class="bg-white flex align-items-center px-7 py-3 shadow-1">
    <Logo @click="$emit('toggleSidebar')" />
    <div class="flex align-items-center ml-auto">
      <Button
        data-cy="adminHeaderMenuBtn"
        class="p-button-text border-0 p-0"
        aria-haspopup="true"
        aria-controls="overlayMenu"
        @click="toggle">
        <Avatar
          :label="avatarLabel"
          size="large"
          class="mr-2 uppercase text-white bg-gray-600"
          shape="circle" />
        <h4 class="text-primary my-0 mr-3">{{ $userStore.name }}</h4>
      </Button>
      <Menu id="overlayMenu" ref="menu" :model="items" :baseZIndex="2001" :popup="true" />
    </div>
  </header>
</template>

<script setup>
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Menu from "primevue/menu";

import Logo from "@/components/Logo";

import { useUserStore } from "@/store";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const $router = useRouter();
const $userStore = useUserStore();
// eslint-disable-next-line no-undef
defineEmits(["toggleSidebar"]);

const avatarLabel = computed(() => `${$userStore.name[0]}`);

const menu = ref();
const items = [
  {
    label: "Profile",
    icon: "pi pi-user-edit",
    command: () => $router.push({ name: "Profile" }),
  },
  {
    label: "Logout",
    icon: "pi pi-power-off",
    command: () => $userStore.logout(),
  },
];
const toggle = (event) => {
  menu.value.toggle(event);
};
</script>

<style lang="scss" scoped>
header {
  z-index: 1500;
}
</style>
